import { css } from "@emotion/core"
import styled from "@emotion/styled"

const Container = styled.section`
    ${({theme}) => css`
      padding: 50px 0;

      .content {
        >div{
          margin-bottom: 54px
        }
        
        .doe-pix-svg{
            margin-top: 100px;
            margin-bottom: 40px;
            max-width: 559px;
            height: 100%;
            max-height: 218px;
        }
        
        h2 {
          color: ${theme.colors.blue};

          font-size: 40px;
          line-height: 60px;
          margin-bottom: 20px;
        }

        p {
          color: ${theme.colors.blue};
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 20px;
        }

        span {
          color: #8b8b8b;
          font-size: 12px;
          line-height: 18px;
        }
      }

      ${theme.queries.small} {
        text-align: justify;
      }

      ${theme.queries.large} {
        padding: 106px 0 192px;

        .content {
          flex: 1;
          margin-right: 50px;

          h2 {
            font-size: 56px;
          }

          p {
            font-size: 23px;
            line-height: 32px;
            margin-bottom: 32px;
          }

          span {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
      
       ${theme.queries.smedium} {
        padding: 25px 0 0;
       
        .content {
          display: flex;
          flex-direction: column;
          margin-right: auto;
          margin-left: auto;
          
          h5 {
            font-size: 20px;
          }
          
          >.content-pix{
           flex-direction: column;
           align-items: center;
            margin-bottom: 25px;
            margin-right: auto;
            margin-left: auto;
          }
                    
          .doe-pix-svg{
            margin: 0 auto;
            width: 95%;
            height:95%;
            margin-bottom: 20px;    
          }
          
          .doe-qrcode-pix-svg {
            width: 100%;
            max-width: 235px;
            height:100%;            
            max-height: 235px;
          }
           
          > svg{
            display: block;
            position: relative;
            inset: unset; 
            margin-right: auto;
            margin-left: auto;
           }          
        }
       }
    `}
  `

const Wrapper = styled.div`
      max-width: 90%;
      width: 1200px;

      display: flex;
      justify-content: space-between;
      margin: 0 auto;
  `

const SubTitle = styled.h5`
    font-size: 26px;
    color: #00a1ff;
    font-weight: 300;
    margin-bottom: 15px;
  `

const Key = styled.h5`
    font-weight: bold;
    font-size: 26px;
    line-height: 47px;
    color: #203760;

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  `

const PixButton = styled.button`
    ${({theme}) => css`
        border: 0;
        cursor: pointer;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 19px;
        color: #ffffff !important;
        text-transform: uppercase;    
    
        padding: 20px 45px;
        background: #00a1ff;
        box-shadow: 0px 14px 24px rgba(0, 161, 255, 0.2);
        border-radius: 10px;
        
        ${theme.queries.smedium} {                  
            display: block;
            margin: 25px auto 0;        
        }    
  `}
  `

export {Container,Key,PixButton,SubTitle,Wrapper};