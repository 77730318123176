/** @jsx jsx */
import {jsx} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Receipts from "../../components/pages/PixTutorial/Receipts"

import Phone from "../../assets/images/svg/phone-pix.svg"

import KeyIcon from "../../assets/images/svg/key-icon.svg"
import DonateWithPix from "../../assets/images/svg/doe-com-pix.svg"
import CopyToClipboard from "../../assets/images/svg/copy-to-clipboard.svg"

import {langNavigate} from "../../helpers"
import {useEffect, useState} from "react";
import isTable from "../../utils/isTable";
import SeoPix from "../../components/SeoPix";
import QrCodeWebMobile from "./QrCode-web-mobile";
import copyToClipboard from "./CopyToClipboard";

import {Wrapper,Container,SubTitle,Key,PixButton} from "./styles"

const Pix = ({pageContext: translation}) => {
    const data = useStaticQuery(graphql`
    query {
      pix: file(relativePath: { eq: "pix-home.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    const [tablet, setTablet] = useState(false)

    useEffect(() => {
        setTablet(isTable())
    }, [])

    return (
        <Layout translation={translation}>
            <SEO lang={translation.currentLang} title={translation.pageName}/>
            <Container id="pix-section">
                <Wrapper style={{position: "relative"}}>
                    <div className="content">
                        <DonateWithPix/>
                        <div className="content-pix" style={{display: "flex"}}>
                            <SeoPix />
                            <KeyIcon style={{marginRight: "18px"}}/>
                            <div style={{marginTop: "5px"}}>
                                <SubTitle>Confira as nossas chaves:</SubTitle>

                                <Key>
                                    doar@universal.org
                                    <CopyToClipboard
                                        onClick={() => {
                                            copyToClipboard("doar@universal.org")
                                        }}
                                    />
                                </Key>

                                <Key>
                                    29.744.778/0001-97
                                    <CopyToClipboard
                                        onClick={() => {
                                            copyToClipboard("29.744.778/0001-97")
                                        }}
                                    />
                                </Key>
                            </div>
                        </div>
                        {tablet && (<QrCodeWebMobile />)}

                        <PixButton
                            onClick={() => langNavigate(19, translation.currentLang)}
                        >
                            NÃO SABE COMO USAR O PIX? VEJA AQUI
                        </PixButton>
                    </div>
                    {!tablet && (<Phone style={{position: "absolute", right: 0}}/>)}
                </Wrapper>
            </Container>
            <Receipts></Receipts>
        </Layout>
    )
}

export default Pix
