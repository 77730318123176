/** @jsx jsx */
import {jsx} from "@emotion/core"
import QrCode from "../../assets/images/svg/qrcode-pix.svg";

const QrCodeWebMobile = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 0,
        }}>
            <QrCode style={{
                marginRight: "auto",
                marginLeft: "auto",
                left: "unset",
                right: "unset",
                top: "unset",
                bottom: "unset",
            }}/>
            <p
                style={{
                    width: "90%",
                    margin: "20px auto",
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "center",
                }}
            >
                *Para utilizar o QR Code, é necessário abrir a área do PIX dentro do
                aplicativo do seu banco e clicar na opção para Pagar com QR Code.
            </p>
        </div>
    );
}

export default QrCodeWebMobile;